export const ADD_COMMENT = 'ADD_COMMENT'
export const ADD_TAG = 'ADD_TAG'
export const APP_LOAD = 'APP_LOAD'
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER'
export const ARTICLE_FAVORITED = 'ARTICLE_FAVORITED'
export const ARTICLE_PAGE_LOADED = 'ARTICLE_PAGE_LOADED'
export const ARTICLE_PAGE_UNLOADED = 'ARTICLE_PAGE_UNLOADED'
export const ARTICLE_SUBMITTED = 'ARTICLE_SUBMITTED'
export const ARTICLE_UNFAVORITED = 'ARTICLE_UNFAVORITED'
export const ASYNC_END = 'ASYNC_END'
export const SET_ONLINE = 'SET_ONLINE'
export const SET_OFFLINE = 'SET_OFFLINE'
export const ASYNC_START = 'ASYNC_START'
export const CHANGE_TAB = 'CHANGE_TAB'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED'
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED'
export const FOLLOW_USER = 'FOLLOW_USER'
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED'
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED'
export const LOGIN = 'LOGIN'
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED'
export const LOGOUT = 'LOGOUT'
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED'
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED'
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED'
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED'
export const REDIRECT = 'REDIRECT'
export const APP_MENU = 'APP_MENU'
export const REGISTER = 'REGISTER'
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED'
export const REMOVE_TAG = 'REMOVE_TAG'
export const SET_PAGE = 'SET_PAGE'
export const SET_SEARCHTXT = 'SET_SEARCHTXT'
export const SET_PRESEARCHTXT = 'SET_PRESEARCHTXT'
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED'
export const SETTINGS_SAVED = 'SETTINGS_SAVED'
export const SETTINGS_FOTO = 'SETTINGS_FOTO'
export const UNFOLLOW_USER = 'UNFOLLOW_USER'
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH'
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR'
export const REDIRECT_TO = 'REDIRECT_TO'

const expd = {
  ADD_COMMENT,
  ADD_TAG,
  APP_LOAD,
  APP_MENU,
  APPLY_TAG_FILTER,
  ARTICLE_FAVORITED,
  ARTICLE_PAGE_LOADED,
  ARTICLE_PAGE_UNLOADED,
  ARTICLE_SUBMITTED,
  ARTICLE_UNFAVORITED,
  ASYNC_END,
  ASYNC_START,
  CHANGE_TAB,
  DELETE_ARTICLE,
  DELETE_COMMENT,
  EDITOR_PAGE_LOADED,
  EDITOR_PAGE_UNLOADED,
  FOLLOW_USER,
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  LOGIN,
  LOGIN_PAGE_UNLOADED,
  LOGOUT,
  PROFILE_FAVORITES_PAGE_LOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  REDIRECT,
  REGISTER,
  REGISTER_PAGE_UNLOADED,
  REMOVE_TAG,
  SET_PAGE,
  SETTINGS_PAGE_UNLOADED,
  SETTINGS_SAVED,
  SETTINGS_FOTO,
  UNFOLLOW_USER,
  UPDATE_FIELD_AUTH,
  UPDATE_FIELD_EDITOR,
}

export default expd
