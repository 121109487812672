// importa componentes da pasta, mas não permite ser importado por nenhum script da pasta
import { lazy } from 'react'
import { FaturamentoA, FaturamentoACommon, sagas } from './reducers'

const App = lazy(() => import('./App'))
const AppEntregas = lazy(() => import('./AppEntregas'))
const Leftbar = lazy(() => import('./Leftbar'))

export const routeData = [
  {
    path: '/faturamentoA/entregas',
    exact: false,
    name: 'Entregas (Fiscal)',
    component: AppEntregas,
    leftbar: Leftbar,
    RBAC: { faturamento: 'l1' },
  },
  {
    path: '/faturamentoA',
    exact: false,
    name: 'Faturamento (Fiscal)',
    component: App,
    leftbar: Leftbar,
    RBAC: { faturamento: 'l1' },
  },
]

export const routes = []

export const menuitems = []

const exp = {
  mod: 'FaturamentoA',
  red: FaturamentoA,
  rc: FaturamentoACommon,
  men: menuitems,
  sagas,
  rot: routes,
  routeData,
}

export default exp
