export const mod ='produtosC'
// deve ser importado tanto pelo frontend quanto pelo backend
const g = {
    m: (m, f) => m,
    f: (m, f) => f,
};
const n = {
    s: (s, p) => s,
    p: (s, p) => p,
};
const c = {
    n: (n, c) => n,
    c: (n, c) => c,
    cam: (s) => s[0].toUpperCase() + s.substr(1),
    nor: (s) => s
};

// export
export const txt = {
    g, n, c,
    _: undefined,
    //
    _fmea: { gen: g.f },
    FMEA: (num = n.s, cas = c.n, gen = g.f) =>
        `FMEA${num('', 's')}`,
    //
    _part: { gen: g.f },
    part: (num = n.s, cas = c.n, gen = g.f) =>
        `${cas('p', 'P')}eça${num('', 's')}`,
    //
    _prop: { gen: g.f },
    prop: (num = n.s, cas = c.n, gen = g.f) =>
        `${cas('p', 'P')}ropriedade${num('', 's')}`,
    //
    _func: { gen: g.f },
    func: (num = n.s, cas = c.n, gen = g.f) =>
        `${cas('f', 'F')}unç${num('ão', 'ões')}`,
    //
    _failmode: { gen: g.m },
    failmode: (num = n.s, cas = c.n, gen = g.m) =>
        `${cas('m', 'M')}odo${num('', 's')} de ${cas('f', 'F')}alha`,
    //
    _effect: { gen: g.m },
    effect: (num = n.s, cas = c.n, gen = g.m) =>
        `${cas('e', 'E')}feito${num('', 's')}`,
    //
    _cause: { gen: g.f },
    cause: (num = n.s, cas = c.n, gen = g.m) =>
        `${cas('c', 'C')}ausa${num('', 's')}`,
    //
    _control: { gen: g.m },
    control: (num = n.s, cas = c.n, gen = g.m) =>
        `${cas('c', 'C')}ontrole${num('', 's')}`,
    //
    oa: (num = n.s, cas = c.n, gen = g.m) =>
        cas(c.nor, c.cam)(`${gen('o', 'a')}${num('', 's')}`)
};

var _at = {};
[
    'PEDIDO_ACT',
    'REDIRECT_TO',
    'PEDIDO_UPDATEFIELD',
    'PEDIDO_WAITING',
    'PEDIDO_LOADED',
    'PEDIDO_UNLOADED',
    'PEDIDO_CHANGETREE',
    'PEDIDO_NODECLICK',
    'PEDIDO_ACT_FUNCS',
    'PEDIDO_ORPRODCRIA',
    'PEDIDO_UPDATEFIELD',
    'PEDIDO_ORPROD_LOADED',
    'PEDIDO_ORPRODCRIA2',
    'PEDIDO_ORPRODVIEW',
    'PEDIDO_ORPRODCLOSE',
    'PEDIDO_PEDIDOS_GETITEM',
    'ORPROD_CLOSE',
    "FIELDCHANGE",
].map(v => _at[v] = 'produtosC' + v);

//
var _act = {
    tagChange: 0,
    tagApply: 0,
    tagInit: 0,
    pedidoView: '',
    SET_SEARCHTXT: 0,
    SET_PRESEARCHTXT: 0,
    hpSetState: '',
    hp: {
        loadData: 0,
        loadDataSources: 0,
        getDataSource: 0,
        setProcessing:0,
        setPrintReady:0,
        unSetProcessing:0,
    },
    HP_ACT: {
        createDB: 0,
    },
    REDIRECT_TO: '',
    HP_UPDATEFIELD: '',
    HP_WAITING: '',
    HP_LOADED: '',
    HP_UNLOADED: '',
    HP_NODECHANGE: '',
    HP_NODECLICK: '',
    HP_ACT_FUNCS: '',
};
const singler = sstruct => {
    for (let k in sstruct)
        if (!sstruct[k])
            sstruct[k] = 'produtosC' + k;
        else
            sstruct[k] = singler(sstruct[k]);
    return sstruct;
};
for (let k in _act)
    if (!_act[k])
        _act[k] = 'produtosC' + k;
    else
        _act[k] = singler(_act[k]);
// const act = _act;
export const at = { ..._at, ..._act };
export const act = at;


//export
export const pathfier = (v) => {
    return v.replace(/#/g, '.childs.').split(/[.:/#]/)
};

// etapas onde se faz necessária a reserva de produtos
export const etapas = ["00", "10", "20", "30", "40", "50",];
export const Act = at;
export const atc = at;

export const defaultTagA = { pretxt: '', txt: ''};

