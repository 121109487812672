// importa componentes da pasta, mas não permite ser importado por nenhum script da pasta
import { lazy } from 'react'
import  { FaturamentoB,FaturamentoBCommon,  sagas } from './reducers'

const App = lazy(() => import('./App'))
const Leftbar = lazy(() => import('./Leftbar'))

export const routeData = [
  { path: '/faturamentoB', exact: false, name: 'Vendas (Pedidos)', component: App,
  leftbar:Leftbar },
  { path: '/dashboard', exact: false, name: 'Vendas (Pedidos)', component: App,
  leftbar:Leftbar },
];

export const routes = [ ]

export const menuitems = [
  [
    'Vendas (Pedidos)',
    '', //<AssignmentIcon/>,
    '/faturamentoB',
  ],
]

const exp = {
  mod: 'FaturamentoB',
  red: FaturamentoB,
  rc: FaturamentoBCommon,
  men: menuitems,
  sagas,
  rot: routes,
  routeData
}

export default exp
