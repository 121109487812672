// importa componentes da pasta, mas não permite ser importado por nenhum script da pasta
import React, { Suspense, lazy } from 'react'
import { Route } from 'react-router-dom'
import { produtosA, produtosACommon, sagas } from './reducers'

// import PedidoView from "./PedidoView";
const PedidoView = lazy(() => import('./PedidoView'))
// import App from './App'
const App = lazy(() => import('./App'))
const Leftbar = lazy(() => import('./Leftbar'))

export const routeData = [
  // { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/produtosA', exact: false, name: 'Produtos', component: App ,
  leftbar:Leftbar },
];

export const routes = [
  // (<Route path="/produtosA/:filter" key={window.keygen()} component={() => (<App/>)}/>),
  <Route
    path="/produtosA/:filter"
    key="/produtosA/:filter"
    component={() => (
      <Suspense fallback={<div>Carregando...</div>}>
        <App />
      </Suspense>
    )}
  />,
  // (<Route path="/produtosA" key={window.keygen()} component={() => (<App/>)}/>),
  <Route
    path="/produtosA"
    key="/produtosA"
    component={() => (
      <Suspense fallback={<div>Carregando...</div>}>
        <App />
      </Suspense>
    )}
    leftbar={() => (
      <Suspense fallback={<div>Carregando...</div>}>
        <Leftbar />
      </Suspense>
    )}
  />,
  // (<Route path="/produtosA/:idx" key={window.keygen()} component={() => (<PedidoView/>)}/>),
  <Route
    path="/produtosA/:idx"
    key="/produtosA/:idx"
    component={() => (
      <Suspense fallback={<div>Carregando...</div>}>
        <PedidoView />
      </Suspense>
    )}
  />,
]

export const menuitems = [
  ['Produtos - Vendas e Estoque', '', '/produtosA/all'],
  ['FPE - Ficha de Portão Eletrônica', '', '/'],
  ['RME - Relatório de Medição Eletrônico', '', '/'],
  ['CM - Cronograma de Montagem', '', '/'],
  ['PS - Pesquisa de Satisfação', '', '/'],
  ['PL - Painel de Logística', '', '/'],
  ['MRP - Planejamento de Recursos Materiais', '', '/'],
  ['Controle de Insumos e Triangulação', '', '/'],
]

const exp = {
  mod: 'produtosA',
  red: produtosA,
  rc: produtosACommon,
  men: menuitems,
  sagas,
  rot: routes,
  routeData,
}

export default exp
