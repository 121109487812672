import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as op from 'object-path'
import { act } from './modconf'

// import {makeStyles} from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//         root: {
//             width: '100%',
//             display: 'block',
//             padding: '1em',
//         },
//         heading: {
//             fontSize: theme.typography.pxToRem(15),
//             fontWeight: theme.typography.fontWeightRegular,
//         },
//         buttonLink: {
//             fontSize: 'small',
//             textDecoration: 'underline',
//             marginRight: '0.5em',
//             cursor: 'pointer',
//             '&$disabled': {
//                 color: 'gray',
//             },
//             '&$enabled': {
//                 color: 'blue',
//             },
//         },
//         disabled: {},
//         enabled: {},
//         inputbox: {
//             width: '100%',
//         },
//     }))
// ;

function DataSource() {
  // const dataSize = useSelector(state => op.get(state, `carteiraA.dataSize`));
  // const clicks = useSelector(state => op.get(state, `carteiraA.clicks`));
  const dispatch = useDispatch()
  const processing = useSelector((state) => op.get(state, `carteiraA.processing`))
  const filtro = useSelector((state) => op.get(state, `carteiraA.filtro`))
  const urlDSListDefault = useSelector((state) => op.get(state, `carteiraA.DS.urlDSListDefault`))
  // const classes = useStyles();

  // useEffect(() => {
  //     let p = {
  //         type: act.hp.loadDataSources,
  //         // payload: apiActs.list({}),
  //     };
  //     dispatch(p);
  // }, []);

  return (
    <div className={"xx"}>
      <div>
        <input
          type="checkbox"
          id="propostas"
          name="propostas"
          onChange={(ev) =>
            dispatch({
              type: act.hpSetState,
              toSet: { filtro: { propostas: ev.target.checked } },
            })
          }
          checked={op.get(filtro, 'propostas', false)}
        />
        <label htmlFor="propostas">Propostas não aprovadas pelos clientes</label>
      </div>
      <div>
        <input
          type="checkbox"
          id="filaAprovar"
          name="filaAprovar"
          onChange={(ev) =>
            dispatch({
              type: act.hpSetState,
              toSet: { filtro: { filaAprovar: ev.target.checked } },
            })
          }
          checked={op.get(filtro, 'filaAprovar', false)}
        />
        <label htmlFor="filaAprovar">Pedidos ainda não Aprovados</label>
      </div>

      <div>
        <input
          type="checkbox"
          id="produzindo"
          name="produzindo"
          onChange={(ev) =>
            dispatch({
              type: act.hpSetState,
              toSet: { filtro: { produzindo: ev.target.checked } },
            })
          }
          checked={op.get(filtro, 'produzindo', false)}
        />
        <label htmlFor="produzindo">Pedidos em Processo ou Concluídos</label>
      </div>
      <div>
        <input
          type="checkbox"
          id="faturado"
          name="faturado"
          onChange={(ev) =>
            dispatch({
              type: act.hpSetState,
              toSet: { filtro: { faturado: ev.target.checked } },
            })
          }
          checked={op.get(filtro, 'faturado', false)}
        />
        <label htmlFor="faturado">Pedidos já Faturados</label>
      </div>
      <div>
        <input
          type="checkbox"
          id="finalizado"
          name="finalizado"
          onChange={(ev) =>
            dispatch({
              type: act.hpSetState,
              toSet: { filtro: { finalizado: ev.target.checked } },
            })
          }
          checked={op.get(filtro, 'finalizado', false)}
        />
        <label htmlFor="finalizado">Pedidos já Finalizados</label>
      </div>
      <div>
        <input
          type="checkbox"
          id="corrigidas"
          name="corrigidas"
          onChange={(ev) =>
            dispatch({
              type: act.hpSetState,
              toSet: { filtro: { corrigidas: ev.target.checked } },
            })
          }
          checked={op.get(filtro, 'corrigidas', false)}
        />
        <label htmlFor="corrigidas">
          Pedidos corrigidos (através do campo 'Código de Rastreio')
        </label>
      </div>
        <button
          onClick={(ev) => {
            ev.preventDefault()
            dispatch({
              type: act.SET_SEARCHTXT,
              //txt: op.get(ev.target, 'value'),
            })
          }}
        >! Atualizar !</button>
    </div>
  )
}

export default DataSource
