export const mod ='carteiraA'

var _at = {};
[
    'PEDIDO_ACT',
    'REDIRECT_TO',
    'PEDIDO_UPDATEFIELD',
    'PEDIDO_WAITING',
    'PEDIDO_LOADED',
    'PEDIDO_UNLOADED',
    'PEDIDO_CHANGETREE',
    'PEDIDO_NODECLICK',
    'PEDIDO_ACT_FUNCS',
    'PEDIDO_ORPRODCRIA',
    'PEDIDO_UPDATEFIELD',
    'PEDIDO_ORPROD_LOADED',
    'PEDIDO_ORPRODCRIA2',
    'PEDIDO_ORPRODVIEW',
    'PEDIDO_ORPRODCLOSE',
    'PEDIDO_PEDIDOS_GETITEM',
    'ORPROD_CLOSE',
    "FIELDCHANGE",
].map(v => _at[v] = 'carteiraA' + v);

//
var _act = {
    tagChange: 0,
    tagApply: 0,
    tagInit: 0,
    pedidoView: '',
    SET_SEARCHTXT: 0,
    SET_PRESEARCHTXT: 0,
    hpSetState: '',
    hp: {
        loadData: 0,
        loadDataSources: 0,
        getDataSource: 0,
        setProcessing:0,
        setPrintReady:0,
        unSetProcessing:0,
    },
    HP_ACT: {
        createDB: 0,
    },
    REDIRECT_TO: '',
    HP_UPDATEFIELD: '',
    HP_WAITING: '',
    HP_LOADED: '',
    HP_UNLOADED: '',
    HP_NODECHANGE: '',
    HP_NODECLICK: '',
    HP_ACT_FUNCS: '',
};
const singler = sstruct => {
    for (let k in sstruct)
        if (!sstruct[k])
            sstruct[k] = 'carteiraA' + k;
        else
            sstruct[k] = singler(sstruct[k]);
    return sstruct;
};
for (let k in _act)
    if (!_act[k])
        _act[k] = 'carteiraA' + k;
    else
        _act[k] = singler(_act[k]);
// const act = _act;
export const at = { ..._at, ..._act };
export const act = at;


//export
export const pathfier = (v) => {
    return v.replace(/#/g, '.childs.').split(/[.:/#]/)
};

// etapas onde se faz necessária a reserva de produtos
export const etapas = ["00", "10", "20", "30", "40", "50",];
export const Act = at;
export const atc = at;

export const defaultTagA = { pretxt: '', txt: ''};

