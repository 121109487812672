// importa componentes da pasta, mas não permite ser importado por nenhum script da pasta
import { lazy } from 'react'
import { mod } from './modconf'
import reducers, { sagas } from './reducers'
import { userDomains } from '../../userRoles'
const produtosArameA = lazy(() => import('./ProdutosArameA'))
const DataSourceArameA = lazy(() => import('./DataSourceArameA'))

export const routeData = [
  // { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  {
    path: '/produtosArameA',
    exact: false,
    name: 'Produtos em Arame',
    // component:  RoleCheck( produtosArameA, 'l2', 'ProdutosArameA',''),
    // leftbar: RoleCheck(DataSourceArameA, 'l2', 'ProdutosArameA',''),
    component: produtosArameA,
    leftbar: DataSourceArameA,
    RBAC: { [userDomains.producao]: 'l1' },
  },
]

const exp = {
  mod,
  red: reducers.reducer,
  rc: reducers.commonReducer,
  sagas: reducers.sagas,
  routeData,
}

export default exp
