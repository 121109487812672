// importa componentes da pasta, mas não permite ser importado por nenhum script da pasta
import React, { lazy, Suspense } from 'react'
// import AssignmentIcon from '@material-ui/icons/Assignment';
import { Route } from 'react-router-dom'
import { carteiraA, carteiraACommon, sagas } from './reducers'
import {Leftbar_void} from './Leftbar'


// import PedidoView from "./PedidoView";
const PedidoView = lazy(() => import('./PedidoView'))
// import App from './App'
const App = lazy(() => import('./App'))
const Leftbar = lazy(() => import('./Leftbar'))

export const routeData = [
  // { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/pedido/:idx', exact: false, name: 'Carteira de Pedidos', component: PedidoView,
  leftbar:Leftbar_void },
  { path: '/carteiraA', exact: false, name: 'Carteira de Pedidos', component: App,
  leftbar:Leftbar },
];

export const routes = [
  // (<Route path="/carteiraA/:filter" key={window.keygen()} component={() => (<App/>)}/>),
  <Route
    path="/carteiraA/:filter"
    key="/carteiraA/:filter"
    component={() => (
      <Suspense fallback={<div>Carregando...</div>}>
        <App />
      </Suspense>
    )}
  />,
  // (<Route path="/carteiraA" key={window.keygen()} component={() => (<App/>)}/>),
  <Route
    path="/carteiraA"
    key="/carteiraA"
    component={() => (
      <Suspense fallback={<div>Carregando...</div>}>
        <App />
      </Suspense>
    )}
    leftbar={Leftbar}
  />,
  // (<Route path="/carteiraA/:idx" key={window.keygen()} component={() => (<PedidoView/>)}/>),
  <Route
    path="/carteiraA/:idx"
    key="/carteiraA/:idx"
    component={() => (
      <Suspense fallback={<div>Carregando...</div>}>
        <PedidoView />
      </Suspense>
    )}
  />,
]

export const menuitems = [
  [
    'Carteira de Pedidos',
    '', //<AssignmentIcon/>,
    '/carteiraA/all',
  ],
]

const exp = {
  mod: 'carteiraA',
  red: carteiraA,
  rc: carteiraACommon,
  men: menuitems,
  sagas,
  rot: routes,
  routeData
}

export default exp
