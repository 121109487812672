// pt - nome da propriedade em portugues
// g - se propriedade pod ser exibida no grid
// n - se numérico


// export const ValueFormatters ={
//     n
// };

const fields = {
    "cod": { pt: 'Código', g: 1, int: 0, unid: '' , type:'xxx',merge:{cellRenderer:"agGroupCellRenderer"}},
     "descr": { pt: 'Descrição', g: 1, n: 0, unid: '',txt:1 ,  large:1,merge:{maxWidth: 600}},
    // "pedido": { pt: 'Pedido', g: 1, int: 1, unid: '' , type:'xxx',cellRenderer:"agGroupCellRenderer"},
    // "local": { pt: 'E', g: 1, n: 0, unid: '',set:1 },
    // "clienteNome": { pt: 'Cliente', g: 1, n: 0, unid: '',txt:1 },
    // "etapaNome": { pt: 'Etapa', g: 1, n: 0, unid: '',set:1 },
    // "cliente": { pt: '-', g: 0, n: 0, unid: '' },
    // "ativado": { pt: 'Ativação', g: 1, n: 0, dlocal: 1, unid: '' },
    // "vendedor": { pt: 'Vendedor', g: 1, n: 0, unid: '',set:1 },
    // "site": { pt: 'Site', g: 1, n: 0, unid: '',set:1 },
    // "valorPedido": { pt: 'Total (R$)', g: 1, n: 1, unid: 'R$' },
    // "contrato": { pt: 'Ativado em', g: 1, n: 0, unid: '' },
    // "rastreamento": { pt: 'Rastreamento', g: 1, n: 0, unid: '' },
    // "projetoNome": { pt: 'Projeto', g: 1, n: 0, unid: '',set:1 },
    // "cfopx": { pt: 'CFOPs', g: 1, n: 0, unid: '',set:1 },
    // "faturamento": { pt: 'Faturado em', g: 1, n: 0,d:0,  unid: '' },
    // "codVend": { pt: '-', g: 0, n: 0, unid: '' },
    // "etapa": { pt: '-', g: 0, n: 0, unid: ',set:1' },
    // "projeto": { pt: '-', g: 0, n: 0, unid: '',set:1 },
    // "cfop": { pt: 'CFOPs', g: 1, n: 0, unid: '' },
};
export default fields;
