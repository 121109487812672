// eslint-disable-next-line no-unused-vars
import React from 'react'
import { at } from './modconf'
import classNamePrefix from 'classnames-prefix'

import { useSelector, useDispatch } from 'react-redux'

// import SearchIcon from '@material-ui/icons/Search'
import * as op from 'object-path'
//
const cn = classNamePrefix('pedido')

function SearchBox() {
  const searchTxt = useSelector((state) => op.get(state, `carteiraA.searchTxt`)) || ''
  const txt = useSelector((state) => op.get(state, `carteiraA.preSearchTxt`)) || ''
  const dirty = useSelector((state) => op.get(state, `carteiraA.dirty`)) || ''
  const dispatch = useDispatch()

  return (
    <div className="nav-link-omnibox">
      <textarea
        className={cn(
          'nav-link-omnibox',
          // (txt !== searchTxt && !(typeof searchTxt !== 'string' && txt === ''))
          dirty && 'nav-link-omnibox-dirty',
        )}
        width="100%"
        rows="4"
        cols="40"
        value={txt}
        onKeyDown={(ev) => {
          if (
            ev.keyCode === 13 &&
            //&& ev.ctrlKey
            !ev.shiftKey
          ) {
            ev.preventDefault()
            dispatch({
              type: at.SET_SEARCHTXT,
              txt: op.get(ev.target, 'value'),
            })
          }
        }}
        onChange={(ev) =>
          dispatch({
            type: at.SET_PRESEARCHTXT,
            txt: ev.target.value,
          })
        }
      ></textarea>
      {/* <SearchIcon
        onClick={() => {
          dispatch({
            type: at.SET_SEARCHTXT,
            txt,
          })
          //dispatch({ type: at.PEDIDO_LOADED, payload: apiActs.list({}) });
        }}
      /> */}
    </div>
  )
}

export default SearchBox
