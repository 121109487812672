// import React, { Component } from 'react'
import SearchBox from './SearchBox'
import DataSource from './DataSource'

export function Leftbar_void() {

    return (
        // <React.Suspense fallback={<div>Carregando...</div>}>
        <div className="leftbar">
        </div>
        // </React.Suspense>
    )
}

function Leftbar() {

    return (
        // <React.Suspense fallback={<div>Carregando...</div>}>
        <div className="leftbar">
            <SearchBox />
            <DataSource />
        </div>
        // </React.Suspense>
    )
}

export default Leftbar