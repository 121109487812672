import produce from "immer";
import "object-path";
import * as op from "object-path";
import * as im from "object-path-immutable";
import reduceReducers from "reduce-reducers";
import { put, select, takeEvery } from 'redux-saga/effects';
import { rh, rt } from "../../agent";
import { ASYNC_END, ASYNC_START } from "../../constants/actionTypes";
import { at } from "./modconf";

export const apiActs = {
    list: (p) => {
        let q = rt(rh("get", `/omieIntegra/v1/produtosC/list`)
            .query(p.filtro || {}));
        return q;
    },
    criaOP: (p) => {
        return rt(rh("post", `/omieIntegra/v1/produtosC/criaOP`, p));
    },
    pedidoView: (p) => {
        return rt(rh("post", `/omieIntegra/v1/produtosC/pedidoView`, p));
    },
    orProdClose: (p) => {
        return rt(rh("post", `/omieIntegra/v1/produtosC/orProdClose`, p));
    },
    listOP: (p) => {
        return rt(rh("get", `/omieIntegra/v1/produtosC/listOP`));
    },
    custoMP: (p) => {
        return rt(rh("get", `/omieIntegra/v1/produtosC/infoEstq`)
            .query({ data: p.data })
            .query({ prodId: p.item })
        );
    },
    getTag: (p) => {
        return rt(rh("post", `/omieIntegra/v1/produtosC/getTag`, p));
    },
    setTag: (p) => {
        return rt(rh("post", `/omieIntegra/v1/produtosC/setTag`, p));
    },
};

export const produtosCCommon = (state = {}, action) => {
    switch (action.type) {

        case at.PEDIDO_WAITING:
            return { ...state };
        case at.PEDIDO_LOADED:
            return {
                ...state,
                header: {
                    ...state.header,
                    showOmniBox: true
                },
            };
        case at.PEDIDO_UNLOADED:
            return {
                ...state,
                header: {
                    ...state.header,
                    showOmniBox: false
                },
            };
        default:
            return null;
    }
};

export const defaultState = (() => {
    let ds = {
        dirty: 0,
        loading: false,
        loaded: false,
        pedidos: [],
        listLen: 0,
        limit: 1000,
        skip: 0,
        page: 0,
        Tagger: {},
        produtosCList: [],
        filtro: {
            faturado: 0,
            finalizado: 0,
            corrigidas: 0,
            produzindo: 1,
        },
    };
    return ds;
})();

const toSetTraps = ({ state, action }) => {
    let filtro = op.get(action.toSet, 'filtro');
    // let txt = op.get(action.toSet, 'txt');
    if (filtro) {
        state = im.set(state, 'dirty', true);
    }
    //
    return im.merge(state, '', op.get(action, 'toSet', {}));
};

const filtraPedidoGrid = ({ lista, campos }) => {
    return lista.map((pedido, idx) => {
        return campos.reduce((acc, curr) => {
            return {
                ...acc,
                [curr]: pedido[curr]
            }
        }, { idx });
    })
};

const filtraPedidoFull = ({ lista, campos }) => {
    return lista;
};

const filtraPedidoDetalhes = ({ lista, campos }) => {
    return lista.map((pedido, idx) => ({
        ...pedido,
        det: undefined,
        idx,
    }));
};

const reducerBase = (state = defaultState, action) => {
    switch (action.type) {
        // --------------------
        case at.tagApply:
            return ((state, action) => {
                let sign = op.get(action, 'sign',);
                let txt = op.get(action, 'txt',);
                return im.merge(state, `Tagger.${sign}`,
                    {
                        pretxt: txt, txt,
                        dirty: false
                    });
            })(state, action);
        // --------------------
        case at.tagInit:
            return ((state, action) => {
                let sign = op.get(action, 'sign', 'foo');
                let txt = op.get(action, 'result.txt', '');
                return im.merge(state, `Tagger.${sign}`,
                    {
                        pretxt: txt, txt,
                        dirty: false
                    });
            })(state, action);
        // --------------------
        case at.tagChange:
            return ((state, action) => {
                let sign = op.get(action, 'sign',);
                let txt = op.get(action, 'txt', '');
                let dirty = (txt != op.get(state, `Tagger.${sign}.txt`, ''))
                return im.merge(state, `Tagger.${sign}`,
                    {
                        pretxt: txt,
                        dirty
                    });
            })(state, action);
        // --------------------
        case at.hpSetState:
            return toSetTraps({ state, action });
        // --------------------
        case at.SET_SEARCHTXT:
            return {
                ...state,
                searchTxt: (((typeof action.txt) === 'undefined') ? state.preSearchTxt : action.txt),
                dirty: false
            };
        // --------------------

        case at.SET_PRESEARCHTXT:
            return {
                ...state,
                preSearchTxt: action.txt || '',
                dirty: true
            };
        // --------------------
        case at.PEDIDO_ORPRODCRIA:
            return { ...state, orProdCria: action.p };
        case at.pedidoView:
            let pedidoView = {
                ...op.get(action, 'payload.data', {}),
                logdata: op.get(action, 'payload.logdata', []),
                logdebug: op.get(action, 'payload.logdebug', []),
            };
            return { ...state, pedidoView };
        case at.PEDIDO_ORPRODVIEW:
            return { ...state, orProdView: { op: action.op } };
        case at.PEDIDO_LOADED:
            let clip = {};
            let paid = action.payload || {};
            let filter = action.filter;
            let count = op.get(paid, 'summary.count', 0);
            //let limit = paid.limit || 100;
            //let skip = paid.skip || 0;
            let produtosCFull = filtraPedidoFull({ lista: paid.data || [] });

            clip = {
                ...clip,
                pages: Math.ceil(count / state.limit),
                listLen: count,
                showLen: produtosCFull.length,
                produtosCFull,
            };
            if (
                state.page >= clip.pages ||
                action.filter !== state.filter ||
                action.txt !== state.txt
            ) {
                clip.page = 0;
                clip.skip = 0;
            }
            return {
                ...state,
                ...clip
            };
        case at.PEDIDO_PEDIDOS_GETITEM:
            let item = action.payload ? action.payload : null;
            return {
                ...state,
                produtosCList: { ...state.produtosCList, [action.index]: item },
            };
        case at.PEDIDO_ORPROD_LOADED:
            return {
                ...state,
                orprods: op.get(action.payload, 'data', []),
            };
        case at.PEDIDO_UNLOADED:
            return state;
        case at.PEDIDO_ACT:
            let act = action.p ? action.p.act : action.act;
            switch (act) {
                case at.setPage:
                    let page = action.page || 0;
                    return {
                        ...state,
                        page,
                        skip: (state.limit || 100) * (page),
                    };
                case "get":
                default:
                    return state;
            }
        case ASYNC_START:
            return { ...state, inProgress: true };
        case ASYNC_END:
            return { ...state, inProgress: false };
        default:
            return state;
    }
};

const immerReducers2 = produce((draft, action) => {
    let f = {};
    switch (action.type) {
        // case at.PEDIDO_LOADED:
        //   return importDataList(draft, im.get(action, "payload.list", []));
        case at.ORPROD_CLOSE:
            // console.log(action);
            switch (action.act) {
                case at.submit:
                    console.log(action.payload);
                    return;
                case at.fieldChange:
                    f.form = op.get(draft, 'orProdClose.form');
                    f.pth = `${(typeof action.item != "undefined") ? 'itens.' + action.item + '.' : ''}${action.f}`;
                    op.set(f.form, f.pth, action.value);
                    return;
                // case at.setPage:
                //     op.set(draft, 'page', action.page || 1);
                //     op.set(draft, 'skip', (action.page || 1) * 100);
                //     op.set(draft, 'limit', 100);
                //     return;

                default:
                    return draft;
            }

        case at.PEDIDO_ACT:
            let act = action.p ? action.p.act : "",
                v = {};
            switch (act) {
                case "restoreCrumb":
                    v.viewKey = im.get(action, "p.viewKey");
                    if (v.viewKey) {
                        op.set(draft, "app.activeView", v.viewKey);
                        op.set(
                            draft,
                            "app.crumbList",
                            im.get(draft, `app.crumbs.${v.viewKey}.restore`)
                        );
                    }
                    return;
                case "orProdCria":
                    return;
                default:
                    // window.alert(`ação desconhecida ${act}`);
                    return draft;
            }
        default:
            // window.alert(`ação desconhecida ${act}`);
            return draft;
    }
}, defaultState);

export const produtosC = reduceReducers(
    defaultState,
    (state = defaultState, action) => {
        let stt = reducerBase(state, action);
        return stt;
    },
    immerReducers2
);

function* setSearchText(action) {
    yield put({
        type: at.hp.getDataSource,
    });
}

function* StateTraps(action) {

    yield true;
}

// Careerga DS espcífica, via lista de URLs
function* getDataSource(action) {
    let limit = yield select(s => s.produtosC.limit);
    let skip = yield select(s => s.produtosC.skip);
    let filter = yield select(s => s.produtosC.filtro);
    let txt = yield select(s => s.produtosC.searchTxt);
    yield put({
        type: at.PEDIDO_LOADED,
        payload: apiActs.list({ filtro: { limit, skip, ...filter, txt } })
    });
}

export const sagas = [
    (function* () {
        yield takeEvery(at.SET_SEARCHTXT, setSearchText);
    })(),
    (function* () {
        yield takeEvery(at.hpSetState, StateTraps);
    })(),
    (function* () {
        yield takeEvery(at.hp.getDataSource, getDataSource);
    })(),
];

const reducers = { sagas, reducer: produtosC, commonReducer: produtosCCommon };
export default reducers;