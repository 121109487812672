// importa componentes da pasta, mas não permite ser importado por nenhum script da pasta
import { lazy } from 'react'
import { mod } from './modconf'
import reducers, { sagas } from './reducers'
const produtosArameA = lazy(() => import('./Main'))
const DataSourceArameA = lazy(() => import('./DataSource'))

export const routeData = [
  // { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/logsPedidosA', exact: false, name: 'Produtos em Arame', component: produtosArameA, leftbar: DataSourceArameA },
];

const exp = {
  mod,
  red: reducers.reducer,
  rc: reducers.commonReducer,
  sagas: reducers.sagas,
  routeData,
}

export default exp
