// pt - nome da propriedade em portugues
// g - se propriedade pod ser exibida no grid
// n - se numérico


// export const ValueFormatters ={
//     n
// };

const fields = {
    "pedido": { pt: 'Pedido', g: 1, type: 'xxx', merge: { cellRenderer: "agGroupCellRenderer" } },
    "local": { pt: 'E', g: 1, n: 0, unid: '', set: 1, merge: { minWidth: 20 } },
    "clienteNome": { pt: 'Cliente', g: 1, n: 0, unid: '', txt: 1, large: 1, merge: { maxWidth: 600 } },
    "etapaNome": { pt: 'Etapa', g: 1, n: 0, unid: '', set: 1 },
    "cliente": { pt: '-', g: 0, n: 0, unid: '', large: 1 },
    "ativado": { pt: 'Ativação', g: 1, n: 0, dlocal: 1, unid: '' },
    "vendedor": { pt: 'Vendedor', g: 1, n: 0, unid: '', set: 1 },
    "site": { pt: 'Site', g: 1, n: 0, unid: '', set: 1 },
    "valorPedido": { pt: 'Total (R$)', g: 1, n: 1, unid: 'R$' },
    "contrato": { pt: 'Ativado em', g: 1, n: 0, unid: '', txt: 1 },
    "rastreamento": { pt: 'Rastreamento', g: 1, n: 0, unid: '', txt: 1 },
    "projetoNome": { pt: 'Projeto', g: 1, n: 0, unid: '', set: 1 },
    "cfopx": { pt: 'CFOPs', g: 1, n: 0, unid: '', set: 1 },
    "faturamento": { pt: 'Faturado em', g: 1, n: 0, d: 1, unid: '' },
    "codVend": { pt: '-', g: 0, n: 0, unid: '' },
    "etapa": { pt: '-', g: 0, n: 0, unid: ',set:1' },
    "projeto": { pt: '-', g: 0, n: 0, unid: '', set: 1 },
    "tags": { pt: 'tags', g: 1, n: 0, unid: '', txt: 1 },
};
export default fields;
