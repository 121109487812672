// importa componentes da pasta, mas não permite ser importado por nenhum script da pasta
import { lazy } from 'react'
import { dashBoardPedidosA, dashBoardPedidosACommon, sagas } from './reducers'

const App = lazy(() =>
  import ('./App'))
//const Leftbar = lazy(() => import('./Leftbar'))

export const routeData = [{
  path: '/dashBoardPedidosA/:report',
  exact: false,
  name: 'Situação de Pedidos',
  component: App,
  //leftbar:Leftbar 
},{
    path: '/dashBoardPedidosA',
    exact: true,
    name: 'Situação de Pedidos',
    component: App,
    //leftbar:Leftbar 
  },
];

export const routes = []

export const menuitems = [
  // [
  //   'Situação de Pedidos',
  //   '', //<AssignmentIcon/>,
  //   '/dashBoardPedidosA/:report',
  // ],
  // [
  //   'Situação de Pedidos',
  //   '', //<AssignmentIcon/>,
  //   '/dashBoardPedidosA',
  // ],
]

const exp = {
  mod: 'dashBoardPedidosA',
  red: dashBoardPedidosA,
  rc: dashBoardPedidosACommon,
  men: [],
  rot: [],
  sagas,
  routeData
}

export default exp
