// importa componentes da pasta, mas não permite ser importado por nenhum script da pasta
import React, { Suspense, lazy } from 'react'
import { Route } from 'react-router-dom'
import { produtosB, produtosBCommon, sagas } from './reducers'

// import PedidoView from "./PedidoView";
const PedidoView = lazy(() => import('./PedidoView'))
// import App from './App'
const App = lazy(() => import('./App'))
const Leftbar = lazy(() => import('./Leftbar'))

export const routeData = [
  // { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/produtosB', exact: false, name: 'Produtos', component: App, leftbar: Leftbar },
];

const exp = {
  mod: 'produtosB',
  red: produtosB,
  rc: produtosBCommon,
  sagas,
  routeData,
}

export default exp
