import produce from "immer"
import { ASYNC_START } from '../../constants/actionTypes';
import reduceReducers from 'reduce-reducers';
import { rh, rt, SITE } from "../../agent";
import { act } from './modconf';
import * as op from 'object-path';
import * as im from 'object-path-immutable';
import { call, put, takeEvery, select } from 'redux-saga/effects'
import moment from "moment";

export const apiActs = {
    faturamentoA: p => {
        return rt(rh('get', `/omieIntegra/v1/faturamentoA`)
            .query(p.filtro));
    },
    get: p => {
        return rt(rh('get', p.url));
    },
};

export const FaturamentoACommon = (state = {}, action) => {
    switch (action.type) {
        case act.HP_WAITING:
            return { ...state };
        default:
            return null;
    }
};

export const defaultState = (() => {
    let ds = {
        data: { summary: [], details: [] },
        processing: false,
        dadosComplementares: {
            "vendedores": [],
            "graficos": [],
            "meses": [],
        },
        loading: false,
        loaded: false,
        clicks: 0,
        dataSize: 0,
        printReady: false,
        mesBase: moment().format("MM/yyyy"),
    };
    return ds;
})();

// Careerga DS espcífica, via lista de URLs
function* getDataSource(action) {
    try {
        let regex = yield select(s => s.FaturamentoA.mesBase);

        let filtro = yield select(s => s.FaturamentoA.filtro);
        const RemoteData = yield call(apiActs.faturamentoA, { filtro: { ...filtro, regex} });
        if (RemoteData.ok) {
            // console.log(RemoteData)

            yield put({
                type: act.hpSetState,
                toSet: {
                    processing: false,
                    loaded: true,
                    data: RemoteData || defaultState.dataSize,
                }
            });
        }
        else {

        }
    }
    catch (e) {
        yield put({ type: "USER_FETCH_FAILED", message: e.message });
        yield put({
            type: act.hpSetState,
            toSet: {
                processing: true,
                loaded: true,
                data: defaultState.data,
            }
        });
    }
}

// Careerga DS espcífica, via lista de URLs
function* HP_LOADED(action) {
    try {
        const dadosComplementares = yield apiActs.get({ url: `${SITE}/config/faturamentoA.json` });
        yield put({ type: act.hpSetState, toSet: { dadosComplementares } });
        yield put({
            type: act.hpSetState,
            toSet: {
                filtro: {
                    opt: action.opt,
                }
            }
        });

        yield put({ type: act.hp.getDataSource});
        // yield window.print();
    }
    catch (e) {
        yield put({ type: "USER_FETCH_FAILED", message: e.message });
        yield put({
            type: act.hpSetState,
            toSet: {
                processing: false,
                data: defaultState.data
            }
        });
    }
}

function* setPrintReady(action) {
    yield put({
        type: act.hpSetState,
        toSet: {
            printReady: true,
        }
    });
}


function* StateTraps(action) {
    let mesBase = op.get(action.toSet, 'mesBase')
    if (mesBase) {
        yield put({
            type: act.hp.getDataSource,
        });
    }
    yield true;
}


export const sagas = [
    (function*() {
        yield takeEvery(act.hp.setPrintReady, setPrintReady);
    })(),
    (function*() {
        yield takeEvery(act.hpSetState, StateTraps);
    })(),
    (function*() {
        yield takeEvery(act.hp.getDataSource, getDataSource);
    })(),
    (function*() {
        yield takeEvery(act.HP_LOADED, HP_LOADED);
    })(),
];

const toSetTraps = ({ state, action }) => {

    let data = op.get(action.toSet, 'data');
    if (data) {
        state = im.set(state, 'chartData', data.summary.map(m => ({ x: m.Density, y: m.TensileStrength })));
    }
    //
    return im.merge(state, '', op.get(action, 'toSet', {}));
};

const reducerBase = (state = defaultState, action) => {
    let v = {};
    switch (action.type) {
        // case   act.HP_LOADED:
        //     return {
        //         ...state,
        //         // data: db.get,
        //     };
        case act.hpSetState:
            return toSetTraps({ state, action });
        case act.hp.setProcessing:
            return {
                ...state,
                processing: true,
            };
        case act.hp.unSetProcessing:
            return {
                ...state,
                processing: false,
            };
        case act.HP_NODECHANGE:
            console.log(action.xxx);
            return {
                ...state,
                clicks: state.clicks + 1,
            };
        case act.HP_ACT:
            let pAct = action.p ? action.p.act : '';
            switch (pAct) {
                case 'get':
                    return {
                        ...state,
                    };
                default:
                    return state;
            }
        case act.HP_ACT.createDB:
            let xAct = action.p ? action.p.act : '';
            switch (xAct) {
                case 'get':
                    return {
                        ...state,
                    };
                default:
                    return state;
            }
        case ASYNC_START:
            if (action.subtype === act.HP_WAITING) {
                return { ...state, inProgress: true };
            }
            return state;
        default:
            return state;
    }
};

const Reducers1 = produce((draft, action) => {
        switch (action.type) {
            case act.HP_ACT:
                let v = {};
                v.act = action.p ? action.p.act : '';
                switch (act) {
                    case 'restoreCrumb':
                        // v.viewKey = im.get(action, 'p.viewKey');
                        // if (v.viewKey) {
                        //     op.set(draft, 'app.activeView', v.viewKey);
                        //     op.set(draft, 'app.crumbList',
                        //         im.get(draft, `app.crumbs.${v.viewKey}.restore`));
                        // }
                        return;
                    case 'dlgRename':
                        // let name = op.get(draft.data, (im.get(action, 'p.path__', '') + '/name').split(/[.:\/#]/));
                        // draft = im.merge(draft, 'app.renameDialog', {
                        //     renaming: true,
                        //     //invalidRename: true,
                        //     renameText: name,
                        //     type: im.get(action, 'p.type'),
                        //     path__: im.get(action, 'p.path__'),
                        //     id: im.get(action, 'p.id'),
                        //     txt: `Renomear '${name}' como`,
                        //     clickYes: im.get(action, 'p.clickYes')
                        // });
                        return draft;

                    default:
                        // window.alert(`ação desconhecida ${act}`);
                        return draft;
                }
            default:
                // window.alert(`ação desconhecida ${act}`);
                return draft;
        }
    },
    defaultState
);

export const FaturamentoA = reduceReducers(defaultState,
    (state = defaultState, action) => {
        let stt = reducerBase(state, action);
        return stt;
    },
    Reducers1,
);
const reducers = { sagas, reducer: FaturamentoA, commonReducer: FaturamentoACommon }
export default reducers;
